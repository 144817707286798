export const continentIndex = {
  EUROPE: 0,
  ASIA: 1,
  AFRICA: 2,
  SOUTHAMERICA: 3,
  NORTHAMERICA: 4,
  OCEANIA: 5,
};

export const colors = {
  yellow: "#F6EABE",
  blue: "#789395",
  autoCompleteOdd: "#87AAAA",
};
